import { SurveyState } from './App'
import { useEffect, FC, Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import exampleFeedbackBar from './images/exampleFeedbackBar.png'

const isMTurkTest =
  window.location.pathname === '/pre_mturk_test' ||
  window.location.pathname === '/pre_mturk_test/'
export const STUDY_REWARD = 5
export const STUDY_BONUS = 2.5
export const DOUBLE_STUDY_BONUS = STUDY_BONUS * 2
export const STUDY_CURRENCY = isMTurkTest ? '€' : '$'
export const isInTestMode =
  window.location.pathname === '/test' || window.location.pathname === '/test/'

export const Welcome: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const [answerIsCorrect, setAnswerIsCorrect] = useState(false)

  const checkCorrectAnswer = (answer: string) => {
    if (answer == 'correct') setAnswerIsCorrect(true)
    else setAnswerIsCorrect(false)
  }

  useEffect(() => {
    if (state.id == null) setState((state) => ({ ...state, id: uuidv4() }))
  }, [setState, state])

  return (
    <div className={'formContainer'}>
      <h1>Welcome to our Survey</h1>
      <p>
        This survey consists of several small games and questionnaires. All in
        all it should take you about 30 minutes to complete. Look at the table
        below for time estimates for each part of the study.
      </p>

      <table className={'center'}>
        <tbody>
          <tr>
            <th>Game 1</th>
            <th>about 6 minutes</th>
          </tr>
          <tr>
            <th>Questionnaire</th>
            <th>about 3 minutes</th>
          </tr>
          <tr>
            <th>Association Game</th>
            <th>about 4 minutes</th>
          </tr>
          <tr>
            <th>Game 2</th>
            <th>about 12 minutes</th>
          </tr>
          <tr>
            <th>Questionnaires</th>
            <th>about 8 minutes</th>
          </tr>
        </tbody>
      </table>

      <p>
        All data collected during this survey will be stored in an anonymized
        format on our server. The data is used for research purposes and
        scientific publications.
      </p>
      <p>
        We recommend completing this survey on a laptop or desktop computer,
        using a mouse. Playing the games on a tablet will not work, and you will
        not receive the reward at the end. One game includes sound, please turn
        on your loudspeakers.
      </p>
      <p>
        For completing this study you receive a payment of {STUDY_REWARD}
        {STUDY_CURRENCY}.
      </p>
      <p>
        To obtain the payment, you need to complete the entire study and submit
        valid data in each and every task (do not leave the games open without
        playing).
      </p>
      <p>
        By performing well in the two longer games (game 1 and game 2), you can
        earn a <b>bonus payment</b> of {STUDY_BONUS}
        {STUDY_CURRENCY} per game. To achieve this bonus, you must belong to 50%
        of the best players for that game. At the top of your screen you will
        see a feedback bar indicating how well you are performing in the game,
        in relation to the other players. Aim for the green area in the feedback
        bar (best 50% of previous and current players), to obtain the bonus
        payment!
      </p>

      <img
        src={exampleFeedbackBar}
        className="feedback-image"
        alt="Example of the feedback bar"
      />
      <FormControl component="fieldset" className={'formControl'}>
        <FormLabel component="legend">
          {' '}
          On which side of the feedback bar do you need to be to receive a bonus
          payment?
        </FormLabel>
        <RadioGroup
          aria-label="question"
          name="question"
          onChange={(event) => checkCorrectAnswer(event.target.value)}
        >
          <FormControlLabel
            value="nope"
            control={<Radio />}
            label="Being on the left side qualifies me for a bonus payment"
          />
          <FormControlLabel
            value="nopenope"
            control={<Radio />}
            label="Both sides qualify me for a bonus payment"
          />
          <FormControlLabel
            value="correct"
            control={<Radio />}
            label="Being on the right side qualifies me for a bonus payment"
          />
        </RadioGroup>
      </FormControl>

      <p>
        By excelling in both games, you earn a bonus payment of 2x{STUDY_BONUS}{' '}
        = {DOUBLE_STUDY_BONUS}
        {STUDY_CURRENCY}. Then you get {STUDY_REWARD + 2 * STUDY_BONUS}
        {STUDY_CURRENCY} in total for your study participation.
      </p>
      <p>Have Fun!</p>

      {!answerIsCorrect && (
        <p className="buttonHelperText">
          Please answer the question correctly.
        </p>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="startButton"
        disabled={!answerIsCorrect}
        onClick={advance}
      >
        Start
      </Button>
    </div>
  )
}
