export class FlowAnswer {
  flowBoring: null | number
  flowDifficulty: null | number

  strategyStuck: null | number
  strategyChange: null | number
  strategyExplore: null | number
  strategyNone: null | number

  bonusExpectation: null | number
  bonusPayOff: null | number

  motivationGame: null | number
  motivationEffort: null | number
  motivationGaveUp: null | number
  motivationUnsure: null | number
  motivationComply: null | number

  explorationApproaches: null | number
  explorationOptions: null | number
  explorationPossibilities: null | number
  explorationConfident: null | number
  explorationWasteTime: null | number

  fearRisk: null | number
  fearAfraid: null | number
  fearIntuition: null | number

  goalApart: null | number
  goalBalance: null | number
  goalAfraid: null | number

  constructor() {
    this.flowBoring = null
    this.flowDifficulty = null

    this.strategyStuck = null
    this.strategyChange = null
    this.strategyExplore = null
    this.strategyNone = null

    this.bonusExpectation = null
    this.bonusPayOff = null

    this.motivationGame = null
    this.motivationEffort = null
    this.motivationGaveUp = null
    this.motivationUnsure = null
    this.motivationComply = null

    this.explorationApproaches = null
    this.explorationOptions = null
    this.explorationPossibilities = null
    this.explorationConfident = null
    this.explorationWasteTime = null

    this.fearRisk = null
    this.fearAfraid = null
    this.fearIntuition = null

    this.goalApart = null
    this.goalBalance = null
    this.goalAfraid = null
  }
}

export const flowQuestions = {
  strategyStuck: 'I found a good strategy and stuck with it.',
  explorationPossibilities:
    'I was curious what could be done in this game and explored possibilities.',
  flowDifficulty: 'It was difficult for me to master this task.',
  fearAfraid:
    'I was afraid to try new strategies in the game, for fear of failure or losing progress.',
  strategyNone: 'I did not have strategies in this game. ',
  strategyExplore: 'I changed my strategy to explore new possibilities. ',
  goalAfraid: 'I was afraid of not getting the bonus payment.', // (-)',
  goalApart:
    'I found something interesting apart from the official game goal. ',
  explorationWasteTime:
    'I did not waste time exploring options that I thought would not work well for me.', // (-)',
  motivationGaveUp: 'At some point I gave up.', // (-)
  motivationComply: 'My main focus was to comply with the instructions.', // (-)
  explorationApproaches: 'I tried many different approaches. ',
  motivationEffort: 'I put in effort to perform well in the game.',
  bonusPayOff: 'I felt like my efforts were not paying off.', // (-)
  fearRisk:
    'I did not want to risk the bonus, so I stuck to a strategy that seemed to work.',
  flowBoring: 'The task was boring.',
  explorationConfident:
    'I was confident in my approach and did not feel the need to try different options.', // (-)',
  motivationGame: 'I',
  strategyChange: 'I changed my strategy to do better in the game.',
  goalBalance:
    'I was able to find a balance between playing for fun and playing strategically to earn the bonus.',
  motivationUnsure: 'I felt lost and unsure of what to do.', // (-)
  fearIntuition:
    'I immersed myself in the game and followed my intuition, rather than worrying about the bonus.', // (-)',
  bonusExpectation: 'I expected to receive the bonus payment.',
  explorationOptions:
    'I wanted to understand my options, so I tried a lot of different things.',
}
