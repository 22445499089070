export interface CollaboUseSuggestion {
  title: string
  items: CollaboUseSuggestionItem[]
  id: string
}
export interface CollaboUseSuggestionItem {
  item: null | string
  id: string
}

export const prompt = 'entertaining games for a group of friends'
export const availableItems = [
  'Apple',
  'Hammer',
  'Jacket',
  'Hat',
  'Ring',
  'Table',
  'Rope',
  'Ketchup',
  'Needle',
  'Mirror',
]

export const CollaboUseDuration = 3 * 60 * 1000
export const CollaboUseTestDuration = 11 * 1000
