export const trainingQuestion =
  'In which domain(s) have you been trained? You can select more than one.'

export interface TrainingOptions {
  agriculture: boolean
  history: boolean
  architecture: boolean
  business: boolean
  computers: boolean
  engineering: boolean
  arts: boolean
  health: boolean
  law: boolean
  languages: boolean
  literature: boolean
  maths: boolean
  music: boolean
  philosophy: boolean
  public: boolean
  sales: boolean
  social: boolean
  science: boolean
  sports: boolean
  teaching: boolean
  tourism: boolean
}

export const trainingOptions = {
  agriculture: 'Agriculture, Veterinary',
  history: 'Anthropology, Archaeology, History',
  architecture: 'Architecture, Construction',
  business: 'Business, Economics',
  computers: 'Computer Science, Digital Technology',
  engineering: 'Engineering, Manufacturing',
  arts: 'Fine Arts – Painting, Photography, Sculpting',
  health: 'Health – Medicine, Nutritional Science, Therapy',
  law: 'Law',
  languages: 'Languages',
  literature: 'Literature',
  maths: 'Mathematics',
  music: 'Music',
  philosophy: 'Philosophy',
  public: 'Public Services',
  sales: 'Sales/Retail',
  social: 'Social sciences – Politics, Psychology, Sociology',
  science: 'Science – Biology, Chemistry, Physics',
  sports: 'Sports',
  teaching: 'Teacher training, Pedagogy, Education science',
  tourism: 'Tourism',
}
