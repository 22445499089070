import { Button, TextField } from '@mui/material'
import React, {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  FC,
  useEffect,
} from 'react'
import { SurveyState } from './App'
import copyImmuneDefenseCode from './images/copyImmuneDefenseCode.png'
import { isInTestMode } from './Welcome'

export const ImmuneDefense: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const [codeHelperText, setCodeHelperText] = useState('')
  const [codeError, setCodeError] = useState(false)

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target as HTMLInputElement
    setState({ ...state, IDCode: code.value })
  }

  const codeIsCorrect = () => {
    return (
      (state.IDCode === 'a' && isInTestMode) ||
      state.IDCode === 'GUTFDBSVAAZ' + state.id
    )
  }

  useEffect(() => {
    if (state.timeMarks.start !== null) return
    const newState = {
      ...state,
      timeMarks: {
        ...state.timeMarks,
        start: Date.now(),
      },
    }
    setState(newState)
    window.localStorage.setItem('surveyState', JSON.stringify(newState))
  }, [state.timeMarks])

  const handleSubmit = () => {
    if (codeIsCorrect()) {
      setCodeHelperText('')
      setCodeError(false)
      advance()
    } else {
      setCodeHelperText(
        'Wrong code. Please enter the code displayed at the end screen of the game',
      )
      setCodeError(true)
    }
  }

  const c = state.ic === 0 ? 'a' : state.ic === 1 ? 'b' : 'c'
  const link =
    'https://immunedefense.corinnaj.me' +
    window.location.pathname +
    '?id=' +
    state.id +
    '&c=vbi' +
    c

  return (
    <div className="formContainer">
      <h1>Survey - Immune Defense Game</h1>

      <p>
        Please click the button below to open the game in a new tab. Play the
        game until you reach the end screen, this will take about 5 minutes. At
        the end of the game a code will be displayed.{' '}
      </p>

      <a href={link} target="_blank" rel="noopener noreferrer">
        <Button variant="contained" color="primary" className="startButton">
          Open the game
        </Button>
      </a>

      <p>1. Copy the code using the button highlighted in red.</p>
      <img
        src={copyImmuneDefenseCode}
        className="image"
        alt="How to copy validation code"
      />

      <p>2. Then paste it here, using Ctrl + v</p>
      <TextField
        error={codeError}
        id="standard-basic"
        placeholder="Immune Defense Code"
        onChange={handleCodeChange}
        helperText={codeHelperText}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={'submitButton formControl'}
        onClick={handleSubmit}
      >
        Next
      </Button>
    </div>
  )
}
