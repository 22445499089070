import React, { FC, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { SurveyState } from './App'

export const CreativeAchievementsFinal: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target as HTMLInputElement
    setState({ ...state, otherCA: text.value })
  }

  function LastPage() {
    return (
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <p>
          Please list other creative achievements not mentioned in the previous
          questions.
        </p>
        <div>
          <TextField
            className="creativeAchievementTextfield"
            multiline
            fullWidth
            variant="outlined"
            onChange={handleTextChange}
          />
          <p style={{ marginTop: '10px' }}></p>
        </div>

        <div className="demographics">
          <p>Check the box next to all sentences that apply to you.</p>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.CAF.creativeAbility ?? false}
                name="0"
                onChange={(_e, checked) =>
                  setState((state) => ({
                    ...state,
                    CAF: { ...state.CAF, creativeAbility: checked },
                  }))
                }
              />
            }
            label={
              'One of the first things people mention about me when introducing me to others is my creative ability in the previously mentioned areas.'
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.CAF.artistic ?? false}
                name="0"
                onChange={(_e, checked) =>
                  setState((state) => ({
                    ...state,
                    CAF: { ...state.CAF, artistic: checked },
                  }))
                }
              />
            }
            label={
              'People regularly accuse me of having an "artistic" temperament.'
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.CAF.professor ?? false}
                name="0"
                onChange={(_e, checked) =>
                  setState((state) => ({
                    ...state,
                    CAF: { ...state.CAF, professor: checked },
                  }))
                }
              />
            }
            label={
              'People regularly accuse me of being an "absent-minded professor" type.'
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className={'formContainer'}>
      <h1>Survey - Questionnaire</h1>
      {LastPage()}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="submitButton"
        onClick={advance}
      >
        Next
      </Button>
    </div>
  )
}
