import React, {
  ChangeEvent,
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { Button, TextField } from '@mui/material'
import { SurveyState } from './App'
import copyShapesCode from './images/copyShapesCode.png'
import { isInTestMode } from './Welcome'

export const Shapes: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const [codeHelperText, setCodeHelperText] = useState('')
  const [codeError, setCodeError] = useState(false)

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, shapesCode: event.target.value })
  }

  const codeIsCorrect = () => {
    return (
      (state.shapesCode === 'a' && isInTestMode) ||
      state.shapesCode === 'HVUGECTWBBA-' + state.id
    )
  }

  const handleSubmit = () => {
    if (codeIsCorrect()) {
      setCodeHelperText('')
      setCodeError(false)
      advance()
      window.localStorage.setItem('surveyState', JSON.stringify(state))
    } else {
      setCodeHelperText(
        'Wrong code. Please enter the code displayed at the endscreen of the game',
      )
      setCodeError(true)
    }
  }

  const c = state.sc === 0 ? 'j' : state.sc === 1 ? 'k' : 'l'
  const link =
    'https://shapes.corinnaj.me/' +
    '?showResults=false' +
    '&allowEarlyExit=false' +
    '&userId=' +
    state.id +
    '&c=ctn' +
    c

  return (
    <div className="formContainer">
      <h1>Survey - Shapes Game</h1>

      <p>
        Please click the button below to open the game in a new tab. Note that
        only games that were played until their end are valid games. Please be
        engaged and play the game continuously without any breaks until you
        reach the end screen, this will take about 13 minutes.{' '}
        <b>Games with long breaks or games that did not end</b> will not be
        considered valid and <b>will not be compensated</b>. At the end of the
        game a code will be displayed.
      </p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Button variant="contained" color="primary" className="startButton">
          Open the game
        </Button>
      </a>

      <p>1. Copy the code using the button highlighted in red.</p>
      <img
        src={copyShapesCode}
        className="image"
        alt="How to copy validation code"
      />

      <p>2. Then paste it here, using Ctrl + v</p>
      <TextField
        error={codeError}
        id="standard-basic"
        placeholder="Shapes Game Code"
        onChange={handleCodeChange}
        helperText={codeHelperText}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={'submitButton formControl'}
        onClick={handleSubmit}
      >
        Next
      </Button>
    </div>
  )
}
