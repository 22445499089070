import React, { useState, FC, Dispatch, SetStateAction } from 'react'
import { Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { SurveyState, numberOfPages } from './App'
import {
  BigFiveData,
  bigFiveQuestions,
  BigFiveStatement,
} from './data/BigFiveData'

export const BigFive: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
  page: number
}> = ({ state, setState, advance, page }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(true)

  function saveAnswer(newValue: number, key: string) {
    const newState = {
      ...state,
      bigFive: { ...state.bigFive, [key]: newValue },
    }
    setState(newState)
    setUnfilledVariables(Object.values(newState.bigFive).includes(null))
  }

  function RadioButtons(key: string) {
    return (
      <RadioGroup
        row
        aria-label="anonymous"
        name="anonymous"
        value={state.bigFive[key as keyof BigFiveData]}
        onChange={(event) => saveAnswer(parseInt(event.target.value), key)}
      >
        <FormControlLabel
          value={-2}
          control={<Radio />}
          labelPlacement="bottom"
          label="Disagree strongly"
        />
        <FormControlLabel
          value={-1}
          control={<Radio />}
          labelPlacement="bottom"
          label="Disagree a little"
        />
        <FormControlLabel
          value={0}
          control={<Radio />}
          labelPlacement="bottom"
          label="Neutral"
        />
        <FormControlLabel
          value={1}
          control={<Radio />}
          labelPlacement="bottom"
          label="Agree a little"
        />
        <FormControlLabel
          value={2}
          control={<Radio />}
          labelPlacement="bottom"
          label="Agree strongly"
        />
      </RadioGroup>
    )
  }

  return (
    <div className={'formContainer'}>
      <h1>Survey - Questionnaire</h1>

      <p>
        Here are a number of characteristics that may or may not apply to you.
        For example, do you agree that you are someone who likes to spend time
        with others? Please select an answer for each statement to indicate the
        extent to which you agree or disagree with that statement.{' '}
      </p>

      {Object.entries(bigFiveQuestions).map(
        ([key, activity]: [string, BigFiveStatement], index: number) => {
          return (
            <div
              key={index}
              className={index % 2 === 1 ? 'highlightGrey' : 'highlightWhite'}
            >
              <p style={{ marginTop: '20px' }}></p>I am someone
              <b>{' ' + activity.statement}</b>
              {RadioButtons(key)}
            </div>
          )
        },
      )}

      <p
        style={{ visibility: unfilledVariables ? 'hidden' : 'visible' }}
        className="buttonPagesLeft"
      >
        Almost done, only {numberOfPages - page} page
        {numberOfPages - page != 1 ? 's' : ''} left.
      </p>
      {unfilledVariables && (
        <p className="buttonHelperText">
          All fields must be filled before proceeding
        </p>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={advance}
        className="submitButton"
        disabled={unfilledVariables}
      >
        Next
      </Button>
    </div>
  )
}
