import React, { FC, Dispatch, SetStateAction, useEffect } from 'react'
import { Button, FormControlLabel, Checkbox } from '@mui/material'
import {
  creativeCategories,
  CreativeCategories,
} from './data/CreativeAchievementsData'
import { SurveyState } from './App'

export const CreativeAchievementsIntro: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  function updateItem(category: keyof CreativeCategories) {
    setState((s) => ({
      ...s,
      CC: {
        ...s.CC,
        [category]: !state.CC[category],
      },
    }))
  }

  useEffect(() => {
    if (state.timeMarks.afterShapes !== null) return
    const newState = {
      ...state,
      timeMarks: {
        ...state.timeMarks,
        afterShapes: Date.now(),
      },
    }
    setState(newState)
    window.localStorage.setItem('surveyState', JSON.stringify(newState))
  }, [state.timeMarks])

  function MainPage() {
    return (
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <p>
          Place a check mark beside the areas in which you feel you have more
          talent, ability, or training than the average person.
        </p>
        {Object.keys(creativeCategories).map((cat: string) => {
          const category: string =
            creativeCategories[cat as keyof CreativeCategories]
          return (
            <FormControlLabel
              key={category}
              control={
                <Checkbox
                  checked={state.CC[cat as keyof CreativeCategories]}
                  name={category}
                  onChange={() => updateItem(cat as keyof CreativeCategories)}
                />
              }
              label={category}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className={'formContainer'}>
      <h1>Survey - Questionnaire</h1>
      {MainPage()}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="submitButton"
        onClick={advance}
      >
        Next
      </Button>
    </div>
  )
}
