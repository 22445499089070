import React, { useState, FC, Dispatch, SetStateAction } from 'react'
import { Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { SurveyState } from './App'
import { FlowAnswer, flowQuestions } from './data/FlowQuestionsData'

const LabelWidth = '70px'

export const FlowQuestionnaire: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  isAfterShapesGame: boolean
  advance: () => void
}> = ({ state, setState, isAfterShapesGame, advance }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(true)
  const [lieItemValue, setLieItemValue] = useState<string | null>(null)

  function saveAnswer(newValue: number, field: keyof FlowAnswer) {
    const newState: SurveyState = {
      ...state,
      flowShapes: isAfterShapesGame
        ? { ...state.flowShapes, [field]: newValue }
        : { ...state.flowShapes },
      flowID: isAfterShapesGame
        ? { ...state.flowID }
        : { ...state.flowID, [field]: newValue },
    }
    setState(newState)
    setUnfilledVariables(
      isAfterShapesGame
        ? Object.values(newState.flowShapes).includes(null) ||
            lieItemValue === null
        : Object.values(newState.flowID).includes(null),
    )
  }

  function RadioButtons(type: keyof FlowAnswer) {
    return (
      <RadioGroup
        row
        aria-label="anonymous"
        name="anonymous"
        value={isAfterShapesGame ? state.flowShapes[type] : state.flowID[type]}
        onChange={(event) => saveAnswer(parseInt(event.target.value), type)}
      >
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={-3}
          control={<Radio />}
          labelPlacement="bottom"
          label={
            <p style={{ margin: 0, textAlign: 'center' }}>
              Strongly
              <br />
              Disagree
            </p>
          }
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={-2}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={-1}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={0}
          control={<Radio />}
          labelPlacement="bottom"
          label="Undecided"
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={1}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={2}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          sx={{ width: LabelWidth }}
          value={3}
          control={<Radio />}
          labelPlacement="bottom"
          label={
            <p style={{ margin: 0, textAlign: 'center' }}>
              Strongly
              <br />
              Agree
            </p>
          }
        />
      </RadioGroup>
    )
  }

  function lieItem() {
    return (
      <div className="lieItem">
        <p style={{ marginTop: '40px' }}></p>
        <b>
          This is a reading test item, please mark the ‘Strongly agree’ answer
          option.
        </b>
        <RadioGroup
          row
          aria-label="lieItem_flowShapes"
          name="lieItem_flowShapes"
          value={lieItemValue}
          onChange={(event) => {
            setLieItemValue(event.target.value)
            setState({
              ...state,
              lieItemFlowShapes: event.target.value === 'correct',
            })
            setUnfilledVariables(
              Object.values(state.flowShapes).includes(null) ||
                event.target.value === null,
            )
          }}
        >
          <FormControlLabel
            value="wrong0"
            control={<Radio />}
            labelPlacement="bottom"
            label="Strongly disagree"
          />
          <FormControlLabel
            value="wrong1"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong2"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong3"
            control={<Radio />}
            labelPlacement="bottom"
            label="Undecided"
          />
          <FormControlLabel
            value="wrong4"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong5"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="correct"
            control={<Radio />}
            labelPlacement="bottom"
            label="Strongly agree"
          />
        </RadioGroup>
      </div>
    )
  }

  return (
    <div className={'formContainer7answers'}>
      <h1>Survey - Game Questionnaire</h1>

      <p>
        Please recall your experience of the game you just completed. There are
        no right or wrong answers, please reply intuitively and honestly to help
        this research.
      </p>

      {Object.keys(flowQuestions).map((key: string, index: number) => {
        const question =
          key === 'motivationGame'
            ? isAfterShapesGame
              ? 'I wanted to create original shapes in the game.'
              : 'I was motivated to stop attackers in the game.'
            : flowQuestions[key as keyof FlowAnswer]
        return (
          <div className="flow" key={index}>
            <p style={{ marginTop: '40px' }}></p>
            <b>{' ' + question}</b>
            {RadioButtons(key as keyof FlowAnswer)}
          </div>
        )
      })}

      {isAfterShapesGame && lieItem()}

      {unfilledVariables && (
        <p className="buttonHelperText">
          All fields must be filled before proceeding
        </p>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={advance}
        className="submitButton"
        disabled={unfilledVariables}
      >
        Next
      </Button>
    </div>
  )
}
