import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { SurveyState } from './App'
import {
  STUDY_REWARD,
  STUDY_BONUS,
  DOUBLE_STUDY_BONUS,
  STUDY_CURRENCY,
} from './Welcome'
//import { isInTestMode } from './Welcome'

export const EndScreen: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
}> = ({ state, setState }) => {
  const [dataSent, setDataSent] = useState(false)

  function buildBonusString() {
    let res =
      'You will receive ' + STUDY_REWARD + STUDY_CURRENCY + ' for this study, '
    if (state.sc == 0 && state.ic == 0)
      res += 'while not being eligible for bonus payments.'
    else {
      res += 'plus a bonus payment of '
      res +=
        state.sc == 0 || state.ic == 0
          ? '' + STUDY_BONUS
          : '' + DOUBLE_STUDY_BONUS
      res += STUDY_CURRENCY + ' for your performance in the '
      if (state.ic == 0) res += 'Shapes game.'
      else if (state.sc == 0) res += 'Immune Defense game.'
      else res += 'two games.'
      res +=
        '\nAfter we have approved your HIT, we will send you the bonus payment within 3 working days through MTurk.'
    }
    return res
  }

  function sendDataToServer() {
    //if (isInTestMode) return
    setDataSent(true)
    const http = new XMLHttpRequest()
    const url = 'https://survey.corinnaj.me/upload.php'
    http.open('POST', url)
    http.setRequestHeader('Content-type', 'application/json')
    const stateToSend: Record<string, unknown> = {
      ...state,
      folder: window.location.pathname.substring(1),
    }
    delete stateToSend.page
    http.send(JSON.stringify(stateToSend))
  }

  useEffect(() => {
    if (state.timeMarks.end !== null) return
    setState({
      ...state,
      timeMarks: {
        ...state.timeMarks,
        end: Date.now(),
      },
    })
  }, [])

  useEffect(() => {
    if (state.timeMarks.end === null || dataSent) return
    window.localStorage.setItem('surveyState', JSON.stringify(state))
    sendDataToServer()
  }, [state.timeMarks.end, dataSent])

  return (
    <div className="formContainer">
      <h1>Congratulations!</h1>
      <p>
        You have reached the end of the survey, thank you for taking part in
        this study.<br></br> In order to receive your payment, please copy the
        code displayed below in MTurk:
      </p>
      <h4>{state.id}</h4>
      <p>{buildBonusString()}</p>
    </div>
  )
}
