import React, { useState, useEffect, FC } from 'react'
import { DemographicsSurvey } from './DemographicsSurvey'
import { LinearProgress } from '@mui/material'
import { ImmuneDefense } from './ImmuneDefense'
import { Shapes } from './Shapes'
import { BigFive } from './BigFive'
import { FlowAnswer } from './data/FlowQuestionsData'
import { FlowQuestionnaire } from './FlowQuestionnaire'
import './App.css'
import { CreativeAchievements } from './CreativeAchievement'
import { EndScreen } from './EndScreen'
import {
  CA,
  CAF,
  CategoryNames,
  categoryOrder,
  CreativeCategories,
  shortCategoryNames,
} from './data/CreativeAchievementsData'
import { GameExperienceSurvey } from './GameExperienceSurvey'
import { Welcome } from './Welcome'
import { CreativeAchievementsFinal } from './CreativeAchievementsFinal'
import { CreativeAchievementsIntro } from './CreativeAchievementsIntro'
import { BigFiveData } from './data/BigFiveData'
import { GameExperienceGenres, GamePlatforms } from './data/GamesExperienceData'
import { CollaboUse } from './CollaboUse'
import { TrainingOptions } from './data/DemographicsData'

export const numberOfPages = 21

interface TimeMarks {
  start: number | null
  afterID: number | null
  afterShapes: number | null
  afterCAQ: number | null
  end: number | null
}

const urlParams = new URLSearchParams(window.location.search)
const ic = urlParams.get('c')?.substring(3, 4)
const sc = urlParams.get('c')?.substring(7, 8)
const immuneCondition =
  ic === 'a'
    ? 0
    : ic === 'b'
    ? 1
    : ic === 'c'
    ? 2
    : Math.floor(Math.random() * 3)
const shapesCondition =
  sc === 'j'
    ? 0
    : sc === 'k'
    ? 1
    : sc === 'l'
    ? 2
    : Math.floor(Math.random() * 3)

export interface SurveyState {
  id?: string
  ic: number
  sc: number

  age?: number
  gender?: number
  degree?: number
  englishLevel?: number
  country: string
  training: TrainingOptions

  gameExp: number | null
  gameGenres: GameExperienceGenres
  gamePlatforms: GamePlatforms

  flowID: FlowAnswer
  flowShapes: FlowAnswer

  autExperience: string

  collaboUseResult: string

  bigFive: BigFiveData

  CC: CreativeCategories
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CA: CA
  otherCA: string
  CAF: CAF

  timeMarks: TimeMarks

  lieItemDemographics: null | boolean
  lieItemFlowShapes: null | boolean
  IDCode: string | null
  shapesCode: string | null
}

function ProgressBar({ page }: { page: number }) {
  return (
    <>
      <LinearProgress
        style={{ height: '20px' }}
        variant="determinate"
        value={(page / numberOfPages) * 100}
      />
      <p className="pagesLeft">
        {numberOfPages - page} page
        {numberOfPages - page != 1 ? 's' : ''} to go
      </p>
    </>
  )
}

export const App: FC = () => {
  const [page, setPage] = useState<number>(() => {
    const page = localStorage.getItem('page')
    if (!page) return 0
    try {
      return parseInt(page)
    } catch {
      return 0
    }
  })

  const [surveyState, setSurveyState] = useState<SurveyState>(() => {
    //TODO What does this do exactly?
    const creativeAchievement = {} as CA
    categoryOrder.map((category) => {
      const shortName: string =
        shortCategoryNames[category as keyof CategoryNames]
      creativeAchievement[shortName] = new Array(8).fill(false)
    })

    const fallback = {
      //Conditions
      id: null,
      ic: immuneCondition,
      sc: shapesCondition,
      //Demographics
      age: null,
      gender: null,
      degree: null,
      englishLevel: null,
      country: '',
      training: {
        agriculture: false,
        history: false,
        architecture: false,
        business: false,
        computers: false,
        engineering: false,
        arts: false,
        health: false,
        law: false,
        languages: false,
        literature: false,
        maths: false,
        music: false,
        philosophy: false,
        public: false,
        sales: false,
        social: false,
        science: false,
        sports: false,
        teaching: false,
        tourism: false,
      },
      //GameExperience
      gameExp: null,
      gameGenres: {
        action: null,
        other: null,
        rpg: null,
        sports: null,
        strategy: null,
        td: null,
      } as GameExperienceGenres,
      gamePlatforms: {
        console: null,
        mobile: null,
        pc: null,
      } as GamePlatforms,
      //ID
      flowID: new FlowAnswer(),
      //Shapes
      flowShapes: new FlowAnswer(),
      //CollaboUse
      collaboUseResult: [],
      //CAQ
      CC: {
        architecture: false,
        culinary: false,
        dance: false,
        business: false,
        humor: false,
        soloSports: false,
        inventions: false,
        music: false,
        science: false,
        teamSports: false,
        theatre: false,
        art: false,
        writing: false,
      } as CreativeCategories,
      CA: creativeAchievement,
      otherCA: '',
      CAF: {
        creativeAbility: false,
        artistic: false,
        professor: false,
      },
      //B5
      bigFive: new BigFiveData(),
      //Other
      timeMarks: {
        start: null,
        afterID: null,
        afterShapes: null,
        afterCAQ: null,
        end: null,
      } as TimeMarks,
      lieItemDemographics: null,
      lieItemFlowShapes: null,
    }

    const data = localStorage.getItem('surveyState')
    if (!data) return fallback
    try {
      return JSON.parse(data)
    } catch {
      return fallback
    }
  })

  function advance(): void {
    setPage((page) => page + 1)
    window.scrollTo(0, 0)
    window.localStorage.setItem('page', page.toString())
    window.localStorage.setItem('surveyState', JSON.stringify(surveyState))
  }
  const handleBeforeunload = (event: BeforeUnloadEvent) => {
    if (page === numberOfPages) return
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload, true)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload, true)
    }
  })

  return (
    <>
      <ProgressBar page={page} />
      {page === 0 && (
        <Welcome
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 1 && (
        <ImmuneDefense
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 2 && (
        <FlowQuestionnaire
          state={surveyState}
          setState={setSurveyState}
          isAfterShapesGame={false}
          advance={advance}
        />
      )}
      {page === 3 && (
        <CollaboUse
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 4 && (
        <Shapes
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 5 && (
        <FlowQuestionnaire
          state={surveyState}
          setState={setSurveyState}
          isAfterShapesGame={true}
          advance={advance}
        />
      )}
      {page === 6 && (
        <CreativeAchievementsIntro
          setState={setSurveyState}
          state={surveyState}
          advance={advance}
        />
      )}
      {page > 6 && page < 17 && (
        <CreativeAchievements
          setState={setSurveyState}
          state={surveyState}
          advance={advance}
          page={page}
        />
      )}
      {page === 17 && (
        <CreativeAchievementsFinal
          setState={setSurveyState}
          state={surveyState}
          advance={advance}
        />
      )}
      {page === 18 && (
        <DemographicsSurvey
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
          page={page}
        />
      )}
      {page === 19 && (
        <GameExperienceSurvey
          state={surveyState}
          setState={setSurveyState}
          page={page}
          advance={advance}
        />
      )}
      {page === 20 && (
        <BigFive
          state={surveyState}
          setState={setSurveyState}
          page={page}
          advance={advance}
        />
      )}
      {page === 21 && (
        <EndScreen state={surveyState} setState={setSurveyState} />
      )}
    </>
  )
}
