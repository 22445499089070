export function timerString(timeInMilliSeconds: number): string {
  const timeInSeconds = Math.round(timeInMilliSeconds / 1000)
  return (
    String(Math.floor(timeInSeconds / 60)).padStart(2, '0') +
    ':' +
    String(timeInSeconds % 60).padStart(2, '0')
  )
}

export enum TestState {
  before,
  during,
  after,
}
